$(document).ready(function() {
		$('body').on('click', '.js-launch_modal', function(event) {
			// console.log('adsad');
			event.preventDefault();
			$('.c-modal').hide();
			element = $(this).attr('href');
			$(element).show(0);
			$('.u-layer').show(0);
			return false;
		});
		$('body').on('click', '.u-layer, .js-close_modal', function(event) {
			event.preventDefault();
			$('.c-modal,.u-layer').hide();
		});

		$('body').on('click', '.js-menutoggle', function(event) {
			event.preventDefault();
			$('.c-nav').addClass('is-active');
		});

		$('body').on('click', '.js-closenav', function(event) {
			event.preventDefault();
			$('.c-nav').removeClass('is-active');
		});

		$('body').on('click', '.js-reset', function(event) {
			event.preventDefault();
			setTimeout(function(){
				$('#contact-form').show();
				$('#status-form').hide();
				document.getElementById('contact-form').reset();
			},1000);
			$('.js-close_modal').trigger('click')
		});


		$('.c-nav').on('click', '.c-nav__link', function(event) {
			event.preventDefault();
			element = $(this).attr('href');
			$('html, body').animate({scrollTop: $(element).offset().top - parseInt(63)}, 600);
			$('.js-closenav').trigger('click');
		});
});

// Enviar emial

(function(){
	emailjs.init("user_GC9NohoYNcq0mIMhBY2jG");
})();

window.onload = function() {
	document.getElementById('contact-form').addEventListener('submit', function(event) {
		event.preventDefault();
		emailjs.sendForm('smtp_server', 'soel_template', this).then(function(response) {
       // console.log('SUCCESS!', response.status, response.text);
       $('#contact-form').hide();
       $('#status-form').show();
    }, function(error) {
    		$('#status-form').children('.o-message').html('No hemos podido enviar su mensaje, favor inténtelo más tarde');
      	// console.log('FAILED...', error);
    });
	});
}




